import { contact_path } from "../Config/Paths";
import MainLayout from "../Layout/MainLayout";
import {
  Layout,
  Typography,
  Button,
  Card,
  Row,
  Col,
  List,
  Space,
  Image,
} from "antd";
import {
  FaClock,
  FaChartLine,
  FaQuestionCircle,
  FaInfoCircle,
  FaGraduationCap,
  FaPlaneDeparture,
  FaCompass,
  FaChalkboardTeacher,
  FaUserTie,
} from "react-icons/fa";
import heroImg from "../Assets/Images/hero.png";
import { useNavigate } from "react-router-dom";
const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text } = Typography;

const featuresData = [
  {
    title: "AI Strategy Coach",
    description:
      "Whether you are an MBA student, considering a career in consulting, or want to sharpen strategic thinking, prepare using our AI Strategy Coach delivered through our unique Case-Study Interview Platform.",
    features: [
      "Realistic Case Simulations with Automated Feedback",
      "Interactive Experience with Performance Insights",
      "Tailored Practice to Sharpen Interview Skills",
    ],
    whyChoose:
      "Equip students with the skills top consulting and finance firms seek, enhancing your MBA program's career-readiness or prepare to leapfrog into senior executive roles.",
    icon: <FaGraduationCap className="icon" />,
  },
  {
    title: "AI Sales Coach",
    description:
      "Boost your sales team's potential with AI Sales Coach, a virtual trainer for continuous improvement in product pitching, objection handling, and client interaction skills.",
    features: [
      "Realistic Sales Simulations",
      "Instant Feedback and Personalized Insights",
      "Unlimited Practice to Track Progress",
    ],
    whyChoose:
      "Empower your sales team to excel in client interactions, increasing sales success and customer satisfaction.",
    icon: <FaChalkboardTeacher className="icon" />,
  },
  {
    title: "AI Interviewer",
    description:
      "Optimize your hiring process with AI Interviewer, an AI-powered platform for efficient, data-driven candidate selection, reducing time-to-hire.",
    features: [
      "Voice-Based Interviews with AI Assessment",
      "High Selection Accuracy and Candidate Insights",
      "Efficiency at Scale for High-Volume Hiring",
    ],
    whyChoose:
      "Transform your hiring efficiency and quality, ideal for high-volume hiring needs in industries like retail, customer service, and tech-driven enterprises.",
    icon: <FaUserTie className="icon" />,
  },
];

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <Row
        className="homepage-content container"
        justify="space-between"
        align="middle"
      >
        <Col xs={24} sm={13} className="text-left">
          <Title level={1} className="text-white">
            Coming Soon...
          </Title>
          <Title level={2} className="text-white">
            AI-based Role Management System
          </Title>
          <Paragraph className="text-white">
            Contact us to be among our first testers and get all customization
            for your organization done for free.
          </Paragraph>
          <Paragraph className="text-white">
            Plus, you get to build and design a world-first product. The best
            part?{" "}
            <span className="text-secondary">
              It's built in Australia for Australian organizations.
            </span>
          </Paragraph>
          <Space>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => navigate(contact_path)}
            >
              Contact Us
            </Button>
          </Space>
        </Col>
        {/* <Col xs={24} sm={11} className="image-column">
          <Image
            className="hero-image"
            src={heroImg} // Replace with your image URL
            alt="Synergenies Hero Image"
            preview={false} // Disable preview if not needed
            style={{ width: "100%", objectFit: "cover" }} // Adjust as necessary
          />
        </Col> */}
      </Row>

      <Col className="section">
        <div className="container homepage">
          <Row justify="center" gutter={[16, 16]}>
            {featuresData.map((coach, index) => (
              <Col key={index} xs={24} sm={12} lg={8}>
                <Card
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="icon-container bg-light">{coach.icon}</div>
                  <Title level={3}>{coach.title}</Title>
                  <Paragraph className="card-description-home">
                    {coach.description}
                  </Paragraph>
                  <Paragraph
                    className="text-secondary-2"
                    style={{
                      marginTop: "0.85rem",
                      marginBottom: "0.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    Features
                  </Paragraph>
                  <List
                    className="homepage-list"
                    dataSource={coach.features}
                    renderItem={(item) => (
                      <List.Item>
                        <Text>{item}</Text>
                      </List.Item>
                    )}
                    style={{ flexGrow: 1 }}
                  />
                  <Paragraph
                    className="text-secondary-2 "
                    style={{ marginTop: "0.85rem", fontWeight: "bold" }}
                  >
                    Why Choose us?
                  </Paragraph>
                  <Paragraph style={{ minHeight: 80 }}>
                    {coach.whyChoose}
                  </Paragraph>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Col>

      <Col className="section text-center bg-light">
        <div className="container">
          <Title level={2}>
            Sound interesting? Book a demo with us to learn more
          </Title>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => navigate(contact_path)}
            className="text-center"
          >
            Book a demo
          </Button>
        </div>
      </Col>
    </MainLayout>
  );
};

export default HomePage;
