export const home_path = "/";
export const login_path = "/login";
export const registration_path = "/sign-up";
export const reset_password_path = "/forgot-password";
export const user_account_path = "/account";
export const rms_path = "/rms";
export const rms_tool_path = "//profiler.synergenies.com/";
export const rms_tool_login_path = "//profiler.synergenies.com/authentication/login";
export const contact_path ="/contact"   
export const terms_condition_path = "/terms-and-conditions";
export const privacy_policy_path = "/privacy-policy";
