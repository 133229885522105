import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/Home";
import { contact_path, home_path, rms_path } from "../Config/Paths";

import ContactPage from "../Pages/Contact";
import RMSPage from "../Pages/RMS";

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={home_path}
          element={
            <>
              <HomePage />
            </>
          }
        />
        <Route
          path={rms_path}
          element={
            <>
              <RMSPage />
            </>
          }
        />

        <Route
          path={contact_path}
          element={
            <>
              <ContactPage />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
